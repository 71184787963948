.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}


@font-face {
  font-family: 'SimplonNorm';
  src: url(../../fonts/SimplonNorm-Light-WebS.woff2) format('woff2');
}

@media only screen and (max-width: 37.5em) {
  [data-zm-modal-overlay] {
      padding: 1.5rem;
      margin-right: 1rem;
  }
}
